



























import Vue from "vue";
import Component from "vue-class-component";
import visuals from '../../../config/visuals.json';
import FancyButton from '../components/FancyButton.vue';

@Component({
  name: 'Home',
  components: {
    FancyButton
  }
})
export default class extends Vue {
  buttons: any;

  beforeMount() {
    const { buttons } = visuals;
    this.buttons = buttons;
  }

  mounted() {
    (this as any).$confetti.stop();
  }
}
